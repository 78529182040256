import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import Cookies from '../components/cookies'

const SupportPage = () => {
    return (
        <Store>
            <Head title="404" />
            <Navigation />
            <Header disable/>
            <Cookies />
        </Store>
    )
}

export default SupportPage
